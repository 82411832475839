import React, { useState, useEffect } from "react";
import DeckGL from "@deck.gl/react";
import ReactMapGL, { FlyToInterpolator } from "react-map-gl";
import { H3HexagonLayer } from "@deck.gl/geo-layers";
import { ScatterplotLayer, PolygonLayer } from "@deck.gl/layers";
import { AmbientLight, PointLight, LightingEffect } from "@deck.gl/core";
import { ArrowDownOutlined } from "@ant-design/icons";
import BinCard from "./ComparisonCard";

import { h3ToGeo } from "h3-js";
import { message } from "antd";

const pointInfo = (info) => {
  console.log(info);
  message.info({
    content: `${info.operator}  ${info.tp.toFixed(2)} Mbp/s`,
    // icon: ArrowDownOutlined,
    style: {
      // marginTop: "20vh",
      position: "fixed",
      bottom: "1px",
      right: "10px",
    },
  });
};

const HexArea = 0.7373276;
export default function Map(props) {
  const ambientLight = new AmbientLight({
    color: [153, 226, 180],
    intensity: 2,
  });

  const pointLight1 = new PointLight({
    color: [250, 163, 7],
    intensity: 0.44,
    position: [-0.144528, 0.739968, 100],
  });

  const pointLight2 = new PointLight({
    color: [202, 240, 248],
    intensity: 0.6,
    position: [-3.807751, 54.104682, 100],
  });

  const lightingEffect = new LightingEffect({
    ambientLight,
    pointLight1,
    pointLight2,
  });
  const material = {
    ambient: 0.7,
    diffuse: 0,
    shininess: 10,
    specularColor: [245, 176, 65],
  };
  const [DGLayer, UpdateDGLayer] = useState([]);
  const [Ambient, UpdateAmbient] = useState(lightingEffect);
  const [LatLng, UpdateLatLng] = useState([95.91, 37.09]);
  const [Hexagons, UpdateHexagons] = useState([]);
  const [Map, UpdateMap] = useState(false);
  const [Elevation, UpdateElevation] = useState(false);
  const [SCard, UpdateCard] = useState(false);
  const [Comparison, UpdateComparison] = useState(false);
  const [HexInfo, UpdateHexInfo] = useState({
    id: null,
    average: null,
    count: null,
    percentage: null,
    oldVal: null,
  });
  const [FilteredHex, UpdateFilteredHex] = useState({
    count: 0,
    average: 0,
    score: 0,
  });
  const [initialViewState, setInitialViewState] = useState({
    longitude: LatLng[0],
    latitude: LatLng[1],
    zoom: 3,
    maxZoom: 20,
    pitch: 0,
    bearing: 0,
  });
  function RangeBox(color) {
    return {
      height: "15px",
      width: "40px",
      backgroundColor: color,
    };
  }

  function operatorColor(operator) {
    if (operator === "DNA" || operator === "VZW") {
      return [222, 49, 99];
    } else if (operator === "Elisa" || operator === "ATT") {
      return [255, 127, 80];
    } else if (operator === "TeliaSonera" || operator === "TMO") {
      return [86, 171, 145];
    } else {
      return [222, 49, 99];
    }
  }

  function createhexLayer(data) {
    if (data) {
      if (data.length) {
        console.log(props.hex);
        let showLibraries = true;
        const hexLayer = new H3HexagonLayer({
          id: "hexagon-layer",
          data: [
            {
              hex: props.hex,
              count: 1000,
            },
          ],
          pickable: true,
          wireframe: false,
          filled: true,
          coverage: 0.998, //spaces between hexagons
          elevationScale: Elevation,
          opacity: 0.7,
          getHexagon: (d) => d.hex,

          getFillColor: (d) => [36, 36, 36],
        });
        const Plotlayer = new ScatterplotLayer({
          id: "scatterplot-layer",
          // data: "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/bart-stations.json",
          data,
          pickable: true,
          opacity: 0.7,
          stroked: false,
          filled: true,
          radiusScale: 1,
          radiusMinPixels: 1,
          radiusMaxPixels: 100,
          lineWidthMinPixels: 1,
          getPosition: (d) => d.coordinates,
          // elevationScale: (d) => d.tp,
          getRadius: (d) => 4,
          getFillColor: (d) => operatorColor(d.operator),
          onClick: ({ object }) => {
            pointInfo(object);
          },
        });
        let hexCenter = h3ToGeo(props.hex);
        setInitialViewState({
          longitude: hexCenter[1],
          latitude: hexCenter[0],
          zoom: 14,
          maxZoom: 30,
          pitch: 60,
          bearing: 10,
          pitch: Elevation ? 70 : 0,
          bearing: Elevation ? 0 : 0,
        });
        UpdateLatLng(hexCenter);
        UpdateDGLayer([hexLayer, Plotlayer]);
      }
    }
  }

  useEffect(() => {
    createhexLayer(props.hexagons);
  }, [props.hexagons, Elevation]);

  useEffect(() => {
    if (props.reset) {
      setInitialViewState({
        longitude: LatLng[0],
        latitude: LatLng[1],
        zoom: 1,
        maxZoom: 20,
        pitch: 0,
        bearing: 0,
      });
      createhexLayer([]);
      UpdateCard(false);
    }
  }, [props.reset]);

  // useEffect(() => {
  //   let hexLayer = createhexLayer(props.selectHexagons);
  //   UpdateDGLayer([hexLayer]);
  // }, [pr const { size } = this.state;ops.selectedhexagons]);
  const { size } = "large";

  return (
    <>
      <div>
        {" "}
        {props.hexagons ? (
          <>
            <div
              style={{
                position: "fixed",
                zIndex: 10,
                left: "570px",
                // marginRight: "30%",
                top: "20px",
                color: "white",
                display: "none",
              }}
            >
              {props.coverage > 0 ? <></> : <></>}
            </div>{" "}
          </>
        ) : (
          <> </>
        )}
        <div className="groupcard">
          {SCard ? <BinCard info={HexInfo} card={UpdateCard} /> : <></>}
        </div>
        <DeckGL
          style={{
            position: "absolute",
            zIndex: 1,
            left: "0px",
            top: "0px",
            height: "500px",
          }}
          initialViewState={initialViewState}
          controller={true}
          effects={[Ambient]}
          layers={DGLayer}
          // getTooltip={({ object }) =>
          //   object && ` ${object.color} and ${object.average} `
          // }
        >
          <ReactMapGL
            reuseMaps
            // mapStyle={mapStyle}
            longitude={LatLng[0]}
            latitude={LatLng[1]}
            preventStyleDiffing={true}
            transitionDuration={1000}
            transitionInterpolator={new FlyToInterpolator()}
            mapStyle="mapbox://styles/mapbox/dark-v10"
            mapStyle={
              Map
                ? "mapbox://styles/mapbox/dark-v10"
                : "mapbox://styles/mapbox/satellite-streets-v11"
            }
            // "mapbox://styles/mapbox/outdoors-v11"
            mapStyle="mapbox://styles/mapbox/dark-v10"
            //`mapbox://styles/mapbox/satellite-v9`
            // mapStyle="mapbox://styles/mapbox/outdoors-v11"
            mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
            mapboxApiAccessToken="pk.eyJ1Ijoic2FsbWFuanZkIiwiYSI6ImNrc3JjaXFlZzBsanUybnRmZHFhNGpmdmYifQ.cYc--QH_zO99dPIsBMSAcQ"
          />
        </DeckGL>
      </div>
    </>
  );
}
