import React from "react";
import StatusBar from "./StatusBar";
import ResultBar from "./ResultTable";
import Measurement from "./Measurment";

import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineRise,
} from "react-icons/ai";
import { Statistic } from "antd";

import "./../App.css";

function SideBar(props) {
  return (
    <>
      <div className="side-bar">
        <>
          {" "}
          <div
            className="pointer"
            style={{ padding: "20px" }}
            onClick={() => {
              props.updatehex(props.hexagons);
            }}
          >
            {" "}
            <Statistic title="Total Bins" value={props.hexagons.length} />
          </div>
        </>
        <StatusBar
          color="green"
          count={
            props.hexcomparison ? props.hexcomparison.IncreasedBins.length : 0
          }
          hexagons={props.hexcomparison.IncreasedBins}
          icon={AiOutlineArrowUp}
          text="Increased"
          updatehex={props.updatehex}
        />
        <></>
        <StatusBar
          color="red"
          count={props.hexcomparison.DecreasedBins.length}
          hexagons={props.hexcomparison.DecreasedBins}
          icon={AiOutlineArrowDown}
          text="Decreased"
          updatehex={props.updatehex}
        />
        <></>
        {/* <StatusBar
          color="orange"
          count={props.hexcomparison.UnChangedBins.length}
          hexagons={props.hexcomparison.UnChangedBins}
          icon={AiOutlineRise}
          text="Unchanged"
          // updatehex={props.updatehex}
          updatehex={props.updatehex}
        />
        <StatusBar
          color="orange"
          count={props.hexcomparison.UnChangedBins.length}
          hexagons={props.hexcomparison.UnChangedBins}
          icon={AiOutlineRise}
          text="New/Old"
          // updatehex={props.updatehex}
          updatehex={props.updatehex}
        /> */}
        {/* <></>
        <ResultBar text={props.hexcomparison.TotalAverage} /> */}
        <>
          <br></br>
          <br></br>
        </>
        {/* <Measurement
          measurement={props.hexcomparison.TotalAverage}
          // matric="mbp/s"
        /> */}
      </div>
    </>
  );
}
export default SideBar;
