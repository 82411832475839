import React, { useState, useEffect } from "react";
import StatusBar from "./StatusBar";
import ResultBar from "./ResultTable";
import Measurement from "./Measurment";

import { VictoryPie, VictoryLabel, VictoryChart, VictoryBar } from "victory";

import { CaretRightFilled, CheckCircleFilled } from "@ant-design/icons";
import { Statistic, Progress, Row, Col, Divider, Button } from "antd";
import { Doughnut } from "react-chartjs-2";

import "./../App.css";

function SideBar(props) {
  const [MaxBins, UpdateMaxBins] = useState([]);
  let hexCount = [];
  useEffect(() => {
    let operatorsArray = [];
    let uniquesOperators = [];
    if (props.hexagons.length) {
      props.hexagons.map((d, x) => {
        operatorsArray.push(d.operator);
      });
      console.log(operatorsArray);
      uniquesOperators = new Set(operatorsArray);
      let result = {};
      for (var i = 0; i < operatorsArray.length; ++i) {
        if (!result[operatorsArray[i]]) result[operatorsArray[i]] = 0;
        ++result[operatorsArray[i]];
      }

      const sortable = Object.entries(result).sort((a, b) => b[1] - a[1]);

      console.log(sortable);
      UpdateMaxBins(sortable);
    }
  }, [props.hexagons]);

  let colorScheme = ["#DE3163", "#FF7F50", "#40E0D0", "cyan", "navy"];
  return (
    <>
      <div
        className="side-bar"
        style={{
          textAlign: "center",
          backgroundColor: "#fff",
          zIndex: 5,
          position: "relative",
          height: "100vh",
          overflow: "auto",
          // width: "300px",
        }}
      >
        <Row justify="center">
          <Col span={24}>
            {" "}
            <br></br>
            <h2>Ranking</h2>
          </Col>

          <Col span={24}>
            {" "}
            <div id="chart">
              {/* <Doughnut data={data} /> */}
              {MaxBins.length ? (
                <>
                  <div>
                    <br></br>
                    <CheckCircleFilled
                      size={30}
                      style={{ fontSize: "50px", color: "#009f48" }}
                    />
                    <h2> {MaxBins[0][0]} </h2> wins in <b>{MaxBins[0][1]} </b>{" "}
                    out of <b>{props.hexagons.length}</b> bins
                  </div>
                  <br></br>
                  <br></br>
                  <div
                    style={{
                      // padding: "0px 20px 0px 20px",
                      width: "80%",
                      marginLeft: "10%",
                      textAlign: "left",
                    }}
                  >
                    {MaxBins.map((i, x) => (
                      <>
                        <Row>
                          <Col span={12}>{i[0]}</Col>
                          <Col span={6}>{i[1]} </Col>
                          <Col span={6}>
                            {" "}
                            {((i[1] * 100) / props.hexagons.length).toFixed(
                              2
                            )}{" "}
                            %{" "}
                          </Col>
                          <Col span={24}>
                            <Divider />
                          </Col>
                        </Row>
                      </>
                    ))}
                  </div>
                  {/* <svg viewBox="0 0 400 400">
                    <VictoryPie
                      standalone={false}
                      width={400}
                      height={400}
                      data={[
                        { x: MaxBins[0].operator, y: MaxBins[0].count },
                        { x: "Elisa", y: 70 },
                        { x: "Teliasonera", y: 55 },
                      ]}
                      padAngle={({ datum }) => 5}
                      innerRadius={90}
                      // labelRadius={100}
                      style={{
                        labels: {
                          fontSize: 14,
                          fill: "#000",
                          position: "absolute",
                          top: "100px",
                        },
                      }}
                      colorScale={[
                        "#DE3163",
                        " #bfbfbf ",
                        "#bfbfbf",
                        "cyan",
                        "navy",
                      ]}
                    />
                    <VictoryLabel
                      textAnchor="middle"
                      style={{ fontSize: 25, fill: "#000", fontWeight: "bold" }}
                      x={200}
                      y={200}
                      text={MaxBins[0].count + "/" + props.hexagons.length}
                    />
                  </svg> */}
                </>
              ) : (
                <></>
              )}
            </div>
          </Col>

          <Col span={24}>
            <br></br>{" "}
          </Col>

          <Col span={24}>
            {" "}
            <h2>Download Speed</h2>
            <br></br>
          </Col>
          <Col span={20}>
            {props.avg.length ? (
              <>
                {props.avg.map((i, index) => (
                  <>
                    <Row>
                      <Col span={12}>
                        {" "}
                        <div>{i._id}</div>
                      </Col>
                      <Col span={12}>
                        {" "}
                        <div>{i.avg.toFixed(2)} Mbp/s</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {" "}
                        <div
                          style={{
                            marginTop: "10px",
                            width: `${(i.avg.toFixed(2) * 100) / 25}%`,
                            height: "10px",
                            maxWidth: "100%",
                            backgroundColor: colorScheme[index],
                          }}
                        ></div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {" "}
                        <div>
                          <br></br>
                        </div>
                      </Col>
                    </Row>
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
export default SideBar;
