import React, { useState, useEffect, useRef } from "react";
import { Input, notification } from "antd";
let autoComplete;
const openNotification = (alert, message) => {
  notification.info({
    message: alert,
    description: message,
    placement: "topRight",
  });
};
function SearchLocationInput(props) {
  const { Search } = Input;
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {}
    );
    //   autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    try {
      const addressObject = autoComplete.getPlace();
      console.log(addressObject);
      let bounds = new window.google.maps.LatLngBounds();
      if (addressObject.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(addressObject.geometry.viewport);
      } else {
        bounds.extend(addressObject.geometry.location);
      }

      let center = bounds.getCenter();
      // props.center([center.lng(), center.lat()]);

      let placesNorthEast = bounds.getNorthEast();
      let placesSouthWest = bounds.getSouthWest();
      let placesNorthWest = new window.google.maps.LatLng(
        placesNorthEast.lat(),
        placesSouthWest.lng()
      );

      // South East
      let placesSouthEast = new window.google.maps.LatLng(
        placesSouthWest.lat(),
        placesNorthEast.lng()
      );

      let placesData = [];
      placesData.push(JSON.parse(JSON.stringify(placesNorthEast)));
      placesData.push(JSON.parse(JSON.stringify(placesNorthWest)));
      placesData.push(JSON.parse(JSON.stringify(placesSouthWest)));
      placesData.push(JSON.parse(JSON.stringify(placesSouthEast)));
      let newBounds = {
        SW: JSON.parse(JSON.stringify(placesSouthWest)),
        NE: JSON.parse(JSON.stringify(placesNorthEast)),
      };
      // console.log(newBounds);

      let coordinatesArray = [];
      if (placesData.length) {
        placesData.map((d) => {
          coordinatesArray.push([d.lng, d.lat]);
        });
      }

      coordinatesArray.push([placesData[0].lng, placesData[0].lat]);

      const query = addressObject.formatted_address;
      let searchparams = props.filters;
      // console.log(searchparams);
      // searchparams.coordinates = coordinatesArray;

      props.updatepolygon(coordinatesArray);

      // props.updatesearch(searchparams);
      // props.city(true);
      // props.changecity(true);
      // updateQuery(coordinatesArray);
    } catch (error) {
      openNotification(
        "Oops!",
        "Google is not recoginzing this address. Please select a city"
      );
    }
  }
  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyChqIAH1G5KEQ0UKkItkZ1AoGnAnLH4HiE&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  useEffect(() => {
    if (props.reset) {
      document.getElementById("googlesearch").value = "";
    }
  }, [props.reset]);

  return (
    <div className="search-location-input">
      <input
        id="googlesearch"
        ref={autoCompleteRef}
        onChange={
          (event) => setQuery(event.target.value)
          // console.log(event.target)
        }
        placeholder="Search Location (Newyork etc.)"
        // value={props.reset ? "" : query}
        style={{
          width: "100%",
          padding: "5px 10px 5px 10px",
          border: "1px solid #d9d9d9",
        }}
      />
    </div>
  );
}

export default SearchLocationInput;
