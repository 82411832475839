import React, { useState, useEffect } from "react";

import CityList from "./CityList";
import OperatorList from "./OperatorList";
import MeasureList from "./MeasurementList";
import GoogleSearch from "./GoogleSearch";
import {
  Divider,
  Button,
  DatePicker,
  Tooltip,
  Switch,
  Collapse,
  Radio,
  Row,
  Col,
} from "antd";
import {
  SearchOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "./../App.css";
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

function SideBarRight(props) {
  const [Comparison, UpdateComparison] = useState(false);
  const [TimeSlot, UpdateTimeSlot] = useState(null);
  const history = useHistory();
  let login = localStorage.getItem("login");
  if (!login) {
    history.push("./login");
  }
  // useEffect(() => {
  //   const history = useHistory();
  //   let login = localStorage.getItem("login");
  //   if (!login) {
  //     history.push("./login");
  //   }
  // }, []);
  function getDates(e, dateString) {
    let firstDate = moment.utc(e[0]._d);
    let secondtDate = moment.utc(e[1]._d);

    props.updatefilters({
      city: props.filters.city,
      operator: props.filters.operator,
      measurement: props.filters.measurement,
      date: {
        first: firstDate,
        second: secondtDate,
      },
      // call: true,
    });
  }
  function FetchResults() {
    // let filters = { ...props.filters };
    // filters.call = true;
    // props.updatefilters(filters);
    props.call(true);
  }

  function Reset() {
    props.updatefilters({
      city: null,
      operator: null,
      measurement: null,
      date: {
        first: null,
        second: null,
      },
      call: false,
    });
    let dates = { ...props.comparison };
    dates.first = null;
    dates.second = null;
    props.updatecomparson(dates);
    props.updatereset(true);
  }

  function CompareDates(e) {
    let dates = { ...props.comparison };
    dates.first = e[0]._d;
    dates.second = e[1]._d;
    props.updatecomparson(dates);
  }

  useEffect(() => {
    if (!Comparison) {
      let dates = { ...props.comparison };
      dates.first = null;
      dates.second = null;
      props.updatecomparson(dates);
      props.updatecompare(false);
    }
  }, [Comparison]);
  const dateFormat = "YYYY-MM-DD";

  function handleSizeChange(e) {
    UpdateTimeSlot(e.target.value);
    props.updatefilters({
      city: props.filters.city,
      operator: props.filters.operator,
      measurement: props.filters.measurement,
      date: {
        first: props.filters.date.first,
        second: props.filters.date.second,
      },
      slot: e.target.value,
      // call: true,
    });
  }
  return (
    <>
      {props.hexagons == 0 ? (
        <>
          <div
            style={{
              backgroundColor: "black",
              opacity: ".4",
              zIndex: 2,
              height: "100vh",
              width: "100%",
              position: "fixed",
              left: "0px",
              top: "0px",
            }}
          ></div>
          <div
            className="side-bar-right"
            style={{ zIndex: 3, position: "fixed" }}
          >
            {/* <div style={{ textAlign: "center" }}>
          {" "}
          <br></br>
          <img src="mesh2.png" style={{ height: "50px" }}></img>
          <br></br>
      
          <br></br>
        </div> */}
            <>
              {/* <Divider orientation="left">Search</Divider> */}
              <>
                <img src="revolution.png" style={{ height: "30px" }}></img>
                <br></br>
                <br></br>
                <GoogleSearch
                  updatefilters={props.updatefilters}
                  updatepolygon={props.updatepolygon}
                  filters={props.filters}
                  reset={props.reset}
                />
              </>

              {/* {props.operators.length ? (
            <>
              <>
                <Divider orientation="left">Operator</Divider>
                <OperatorList
                  operators={props.operators}
                  selectoperator={props.selectoperator}
                />
                <br></br>
              </>
              <>
                <Divider orientation="left">Measurement</Divider>
                <MeasureList
                  measure={props.measure}
                  selectmeasure={props.selectmeasurement}
                />
                <br></br>
              </>
              <>
                <Divider orientation="left">Time Slot</Divider>
                <>
                  <Switch
                    checkedChildren="Weenkends"
                    unCheckedChildren="Weekdays"
                    defaultChecked
                  />
                  <br></br>
                  <br></br>
                </>
                <Radio.Group
                  value={TimeSlot}
                  size="medium"
                  onChange={handleSizeChange}
                  buttonStyle={{ padding: "5px" }}
                >
                  <Radio.Button value="[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]">
                    12AM-12PM
                  </Radio.Button>
                  <Radio.Button value="[6,7,8,9]">06AM-10AM</Radio.Button>
                  <Radio.Button value="[10,11,12,13]">10AM-2PM</Radio.Button>
                  <Radio.Button value="[14,15,16,17]">2PM-6PM</Radio.Button>
                  <Radio.Button value="[18,19,20,21]">6PM-10PM</Radio.Button>
                </Radio.Group>
              </>
              <>
                <Divider orientation="left">Date</Divider>

                <RangePicker
                  style={{ width: "100%" }}
                  picker="date"
                  defaultValue={[
                    moment(new Date(), dateFormat),
                    moment(new Date(), dateFormat).subtract(1, "month"),
                  ]}
                  format={dateFormat}
                  onChange={(e) => {
                    getDates(e);
                  }}
                />
                <br></br>
              </>

              <Divider orientation="left">
                {" "}
                <CaretDownOutlined />
                Compare Date
              </Divider>

              <RangePicker
                style={{ width: "100%" }}
                picker="date"
                onChange={(e) => {
                  CompareDates(e);
                }}
              />

              <br></br>
            </>
          ) : (
            ""
          )} */}
            </>

            <>
              <br></br>
              <Row justify="right">
                <Col span={18}>
                  {props.polygon.length ? (
                    <Tooltip title="clear search">
                      <Button
                        style={{
                          float: "right",
                          display: "none",
                        }}
                        size="medium"
                        type="dashed"
                        onClick={() => {
                          Reset();
                        }}
                      >
                        Clear Search
                      </Button>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col span={6}>
                  <Tooltip title="search">
                    <Button
                      style={{ float: "right" }}
                      size="medium"
                      type="primary"
                      icon={<SearchOutlined />}
                      loading={props.loading}
                      onClick={() => {
                        FetchResults();
                      }}
                    >
                      Search
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ color: "white" }}>
                  <br></br>

                  <div
                    style={{
                      position: "fixed",
                      bottom: "20px",
                      maxWidth: "500px",
                      textAlign: "center",
                      opacity: 0.5,
                    }}
                  >
                    Copyright &copy; 2021
                    <div>
                      {" "}
                      Disclaimer: Only for demo purposes. Enablers Inc holds no
                      liability against the data and information provided.
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          </div>
        </>
      ) : (
        <>
          {" "}
          <Tooltip title="clear search">
            <Button
              style={{
                float: "right",
                // marginRight: "10px",
                // width: "100%",
                // display: "none",
                position: "fixed",
                zIndex: 10,
                right: "10px",
                bottom: "10px",
              }}
              icon={<UndoOutlined />}
              size="medium"
              type="dashed"
              shape="round"
              onClick={() => {
                // Reset();
                window.location.reload(false);
              }}
            >
              Reset
            </Button>
          </Tooltip>
        </>
      )}
    </>
  );
}
export default SideBarRight;
