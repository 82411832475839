import React, { useState, useEffect } from "react";
import DeckGL from "@deck.gl/react";
import ReactMapGL, { FlyToInterpolator } from "react-map-gl";
import { H3HexagonLayer } from "@deck.gl/geo-layers";
import { AmbientLight, PointLight, LightingEffect } from "@deck.gl/core";
import BinCard from "./../components/BinInfoCard";
import { Select } from "antd";
import { h3ToGeo } from "h3-js";
import { Button, Radio, Switch, Row, Col, Statistic, Card } from "antd";

import * as d3 from "d3";
import {
  BulbOutlined,
  SignalFilled,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
const { Option } = Select;
const HexArea = 0.7373276;
export default function Map(props) {
  const ambientLight = new AmbientLight({
    color: [153, 226, 180],
    intensity: 2,
  });

  const pointLight1 = new PointLight({
    color: [250, 163, 7],
    intensity: 0.44,
    position: [-0.144528, 0.739968, 100],
  });

  const pointLight2 = new PointLight({
    color: [202, 240, 248],
    intensity: 0.6,
    position: [-3.807751, 54.104682, 100],
  });

  const lightingEffect = new LightingEffect({
    ambientLight,
    pointLight1,
    pointLight2,
  });
  const material = {
    ambient: 0.7,
    diffuse: 0,
    shininess: 10,
    specularColor: [245, 176, 65],
  };
  const [DGLayer, UpdateDGLayer] = useState([]);
  const [Ambient, UpdateAmbient] = useState(lightingEffect);
  const [LatLng, UpdateLatLng] = useState([95.91, 37.09]);
  const [Hexagons, UpdateHexagons] = useState([]);
  const [Map, UpdateMap] = useState(false);
  const [Elevation, UpdateElevation] = useState(false);
  const [SCard, UpdateCard] = useState(false);
  const [Comparison, UpdateComparison] = useState(false);
  const [HexInfo, UpdateHexInfo] = useState({
    id: null,
    average: null,
    count: null,
    percentage: null,
    oldVal: null,
  });
  const [FilteredHex, UpdateFilteredHex] = useState({
    count: 0,
    average: 0,
    score: 0,
  });
  const [initialViewState, setInitialViewState] = useState({
    longitude: LatLng[0],
    latitude: LatLng[1],
    zoom: 3,
    maxZoom: 20,
    pitch: 0,
    bearing: 0,
  });
  function RangeBox(color) {
    return {
      height: "15px",
      width: "40px",
      backgroundColor: color,
    };
  }
  function selectHexagons(hexid) {
    UpdateHexInfo({
      id: hexid._id,
      count: hexid.count,
      average: hexid.average ? hexid.average : 0,
      percetnage: hexid.percentage,
      oldVal: hexid.oldVal,
    });
    UpdateCard(true);
  }

  function createhexLayer(data) {
    if (data) {
      if (data.length) {
        let showLibraries = true;
        const hexLayer = new H3HexagonLayer({
          id: props.compare ? "comaparison-layer" : "hexagon-layer",
          data,
          pickable: true,
          // extruded: true,
          wireframe: false,
          filled: true,
          coverage: 0.998, //spaces between hexagons
          elevationScale: Elevation,
          opacity: 0.5,
          getHexagon: (d) => d._id,
          getElevation: (d) => Math.abs(d.count),
          getFillColor: (d) => d.color,
          material,
          // ? // : [255, 193, 0],
          // Comparison
          // ?

          onClick: (d) => {
            selectHexagons(d.object);
          },
          updateTriggers: {
            getFillColor: (d) => d.color,
            getColor: (d) => d.color,
          },
          transitions: {
            getFillColor: 100,
          },
        });

        /**
         * @orginal hex
         */

        const allexLayer = new H3HexagonLayer({
          id: "hexagon-layer",
          data: props.allhex,
          pickable: true,
          // extruded: true,
          wireframe: false,
          filled: true,
          coverage: 0.998, //spaces between hexagons
          elevationScale: Elevation,
          opacity: 0.2,
          getHexagon: (d) => d.id,
          getElevation: (d) => Math.abs(d.count),
          getFillColor: (d) => [10, 10, 10],
          material,
          // ? // : [255, 193, 0],
          // Comparison
          // ?

          onClick: (d) => {
            selectHexagons(d.object);
          },
          updateTriggers: {
            getFillColor: (d) => d.color,
            getColor: (d) => d.color,
          },
          transitions: {
            getFillColor: 100,
          },
        });

        let hexCenter = h3ToGeo(data[0]._id);
        setInitialViewState({
          longitude: hexCenter[1],
          latitude: hexCenter[0],
          zoom: 9.5,
          maxZoom: 17,
          pitch: 60,
          bearing: 10,
          pitch: Elevation ? 70 : 0,
          bearing: Elevation ? 0 : 0,
        });
        // UpdateLatLng(hexCenter);
        UpdateDGLayer([hexLayer]);
      }
    }
  }

  useEffect(() => {
    createhexLayer(props.hexagons);
  }, [props.hexagons, Elevation]);
  useEffect(() => {
    if (props.reset) {
      setInitialViewState({
        longitude: LatLng[0],
        latitude: LatLng[1],
        zoom: 1,
        maxZoom: 20,
        pitch: 0,
        bearing: 0,
      });
      createhexLayer([]);
      UpdateCard(false);
    }
  }, [props.reset]);

  useEffect(() => {
    if (props.compare) {
      console.log(props);
      if (props.comparison.Combined) {
        console.log(props.comparison);
        let combinedData = [
          ...props.comparison.IncreasedBins,
          ...props.comparison.DecreasedBins,
          // ...props.comparison.NewBins,
          ...props.comparison.UnChangedBins,
        ];
        // console.log("Compared Hex");
        // console.log(combinedData.length);
        // if (combinedData.length) {
        //   createhexLayer(combined);
        // }

        createhexLayer(combinedData);
      }
    } else {
      createhexLayer(props.hexagons);
    }
  }, [props.compare]);

  // useEffect(() => {
  //   let hexLayer = createhexLayer(props.selectHexagons);
  //   UpdateDGLayer([hexLayer]);
  // }, [pr const { size } = this.state;ops.selectedhexagons]);
  const { size } = "large";

  return (
    <>
      <div>
        {" "}
        {props.hexagons.length ? (
          <>
            <div
              style={{
                position: "fixed",
                zIndex: 10,
                left: "570px",
                // marginRight: "30%",
                top: "20px",
                color: "white",
                display: "none",
              }}
            >
              {props.coverage > 0 ? (
                <>
                  <div
                    className="site-statistic-demo-card"
                    style={{ width: "200px" }}
                  >
                    <Row gutter={16}>
                      <Col span={24}>
                        <Card>
                          <Statistic
                            title={
                              <>
                                Km<sup>2</sup> Covered
                              </>
                            }
                            value={(props.hexagons.length * HexArea).toFixed(2)}
                            precision={2}
                            valueStyle={{ color: "  #00b4d5" }}
                            // prefix={<ArrowUpOutlined />}
                            // suffix="%"
                          />
                          <Statistic
                            title="Hex Coverage"
                            value={props.coverage}
                            precision={2}
                            valueStyle={{ color: "#5e6769 " }}
                            // prefix={<ArrowUpOutlined />}
                            suffix="%"
                          />
                          <Statistic
                            title="Total Bins"
                            value={props.hexagons.length}
                            precision={0}
                            valueStyle={{ color: "#5e6769 " }}
                            // prefix={<ArrowUpOutlined />}
                          />
                          <Statistic
                            title="Max Value"
                            value={
                              props.hexagons[0].average
                                ? props.hexagons[0].average.toFixed(2)
                                : 0
                            }
                            precision={2}
                            valueStyle={{ color: "#27d500" }}
                            prefix={<ArrowUpOutlined />}
                          />
                          <Statistic
                            title="Min Value"
                            value={
                              props.hexagons[props.hexagons.length - 1].average
                                ? props.hexagons[
                                    props.hexagons.length - 1
                                  ].average.toFixed(2)
                                : 0
                            }
                            precision={2}
                            valueStyle={{ color: "#d58100 " }}
                            prefix={<ArrowDownOutlined />}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  {/* <h1 style={{ color: "white" }}>
                    {(props.hexagons.length * HexArea).toFixed(2)} Km
                    <sup>2</sup> covered
                  </h1>
                  <h1 style={{ color: "white" }}>
                    {props.hexagons.length} Bins
                  </h1>
                  <h1 style={{ color: "white" }}>
                    {props.hexagons[0].average.toFixed(2)} Max
                  </h1>
                  <h1 style={{ color: "white" }}>
                    {props.hexagons[props.hexagons.length - 1].average.toFixed(
                      2
                    )}{" "}
                    Min
                  </h1> */}
                </>
              ) : (
                <></>
              )}
            </div>{" "}
            <div
              style={{
                position: "fixed",
                zIndex: 10,
                right: "20px",
                // marginRight: "30%",
                top: "20px",
              }}
            >
              <>
                <Switch
                  style={{ marginLeft: "20px" }}
                  checkedChildren={<SignalFilled />}
                  unCheckedChildren={<SignalFilled />}
                  defaultChecked={false}
                  onChange={(e) => UpdateElevation(!Elevation)}
                />
              </>
              <>
                <Switch
                  style={{ marginLeft: "20px" }}
                  checkedChildren={<BulbOutlined />}
                  unCheckedChildren={<BulbOutlined />}
                  defaultChecked={false}
                  onChange={(e) => UpdateMap(!Map)}
                />
              </>
            </div>
            <div
              style={{
                position: "fixed",
                zIndex: 2,
                top: "60px",
                right: "30px",
              }}
            >
              {" "}
              <Row>
                <Col span={4} order={0}></Col>
                <Col span={4} order={5}>
                  <div style={RangeBox("rgba(20, 116, 111)")}></div>
                </Col>
                <Col span={4} order={4}>
                  <div style={RangeBox("rgba(53, 143, 128)")}></div>
                </Col>
                <Col span={4} order={3}>
                  <div style={RangeBox("rgba(86, 171, 145)")}></div>
                </Col>
                <Col span={4} order={2}>
                  <div style={RangeBox("rgba(120, 198, 163)")}></div>
                </Col>
                <Col span={4} order={1}>
                  <div style={RangeBox("rgba(153, 226, 180)")}></div>
                </Col>
              </Row>
              <Row
                style={{
                  color: "#8e8a8a",
                  fontSize: ".8em",
                  textAlign: "center",
                }}
              >
                <Col span={4} order={1}>
                  Poor
                </Col>
                <Col span={4} order={3}></Col>
                <Col span={4} order={2}></Col>
                <Col span={4} order={1}></Col>
                <Col span={4} order={0}></Col>
                <Col span={4} order={5}>
                  Good
                </Col>
              </Row>
            </div>{" "}
          </>
        ) : (
          <> </>
        )}
        <div className="groupcard">
          {SCard ? <BinCard info={HexInfo} card={UpdateCard} /> : <></>}
        </div>
        <DeckGL
          style={{ position: "fixed", zIndex: 1, left: "0px", top: "0px" }}
          initialViewState={initialViewState}
          controller={true}
          effects={[Ambient]}
          layers={DGLayer}
          // getTooltip={({ object }) =>
          //   object && ` ${object.color} and ${object.average} `
          // }
        >
          <ReactMapGL
            reuseMaps
            // mapStyle={mapStyle}
            longitude={LatLng[0]}
            latitude={LatLng[1]}
            preventStyleDiffing={true}
            transitionDuration={1000}
            transitionInterpolator={new FlyToInterpolator()}
            mapStyle="mapbox://styles/mapbox/dark-v10"
            mapStyle={
              Map
                ? "mapbox://styles/mapbox/dark-v10"
                : "mapbox://styles/mapbox/satellite-streets-v11"
            }
            // "mapbox://styles/mapbox/outdoors-v11"
            // mapStyle="mapbox://styles/mapbox/dark-v10"
            //`mapbox://styles/mapbox/satellite-v9`
            // mapStyle="mapbox://styles/mapbox/outdoors-v11"
            // mapStyle="mapbox://styles/mapbox/light-v10"
            mapboxApiAccessToken="pk.eyJ1Ijoic2FsbWFuanZkIiwiYSI6ImNrc3JjaXFlZzBsanUybnRmZHFhNGpmdmYifQ.cYc--QH_zO99dPIsBMSAcQ"
          />
        </DeckGL>
      </div>
    </>
  );
}
