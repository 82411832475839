import React from "react";
import "./../App.css";
import { Select } from "antd";
const { Option } = Select;
function MeasurementList(props) {
  return (
    <>
      <Select
        defaultValue="RSRP"
        style={{ width: "100%" }}
        onChange={(e) => props.selectmeasure(e)}
      >
        <Option value="RSRP">LTE Coverage (RSRP)</Option>
        <Option value="RSCP">RSCP</Option>
        <Option value="SINR">SINR</Option>
        <Option value="EcNo">EcNo</Option>
        <Option value="RxLev">RxLev</Option>
        <Option value="DLThroughput">DL Throughput</Option>
      </Select>
    </>
  );
}
export default MeasurementList;
