import React, { useEffect } from "react";

import { Card, Typography, Row, Col, Rate, Divider } from "antd";
import "./../App.css";
import {
  ArrowLeftOutlined,
  SmileTwoTone,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;
const desc = ["terrible", "bad", "normal", "good", "wonderful"];

function BinCard(props) {
  return (
    <>
      <Card bordered={true} style={{ width: 400 }}>
        <Row>
          <Col span={24}>
            <Title level={5}>
              <ArrowLeftOutlined onClick={() => props.card(false)} />
            </Title>
          </Col>
        </Row>
        <Row>
          
        </Row>

        <Row>
          <Col span={12}>
            <Text strong>hex</Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text level={4} bold>
              {props.info.id}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Text strong>average</Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text level={4} bold>
              {Number(props.info.average).toFixed(4)}
            </Text>
          </Col>
        </Row>

        {props.info.percetnage ? (
          <>
            <Row>
              <Col span={12}>
                <Text strong>previous average</Text>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Text level={4} bold>
                  {Number(props.info.oldVal).toFixed(4)}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Text strong>variation</Text>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Text level={4} bold>
                  {Number(props.info.percetnage > 0) ? (
                    <ArrowUpOutlined style={{ color: "green" }} />
                  ) : (
                    <ArrowDownOutlined style={{ color: "red" }} />
                  )}
                  {Number(props.info.percetnage).toFixed(4)}%
                  {/* {Number(props.info.percetnage > 0)
                    ? "increased"
                    : "decreased"} */}
                </Text>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}

        <Row>
          <Col span={12}>
            <Text strong>sample count</Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text level={4} bold>
              {props.info.count}
            </Text>
          </Col>
        </Row>
      </Card>
    </>
  );
}
export default BinCard;
