import React from "react";
// import { Progress } from "antd";
import { Result } from "antd";
import { PushpinTwoTone } from "@ant-design/icons";

import "./../App.css";

function StatusBar(props) {
  return <>{/* <Result icon={""} title="" />, */}</>;
}
export default StatusBar;
