import React, { useEffect, useState } from "react";
import ComaprisonMap from "./ComparisonMap";
import {
  Card,
  Typography,
  Row,
  Col,
  Rate,
  Divider,
  Progress,
  Statistic,
  Button,
} from "antd";
import "./../App.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import axios from "axios";
const { Title, Text } = Typography;
const desc = ["terrible", "bad", "normal", "good", "wonderful"];

function BinCard(props) {
  const [HexMap, UpdateHexMap] = useState(0);
  const [Loading, UpdateLoading] = useState(0);
  const [PointsData, UpdatePointsData] = useState([]);
  let colorScheme = ["#DE3163", "#FF7F50", "#40E0D0", "cyan", "navy"];
  const URL = "https://api.revolutionapp.io/api/qos/";
  async function ShowPoints() {
    UpdateHexMap(!HexMap);
    if (!HexMap) {
      UpdateLoading(1);
      let data = await axios.post(
        // "https://api.revolutionapp.io/api/qos/points/hex",
        "https://api.revolutionapp.io/api/qos/points/hex",
        // "http://localhost:3004/points/hex",
        {
          hex: props.info.id,
        }
      );

      if (data.data.length) {
        UpdatePointsData(data.data);
        UpdateLoading(0);
        UpdateHexMap(!HexMap);
      } else {
        UpdateLoading(0);
        UpdateHexMap(0);
      }
    }
  }

  useEffect(() => {
    ShowPoints();
  }, [props.info.id]);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "500px",
          overflow: "hidden",
        }}
      >
        <div style={{ flex: 1 }}>
          {/* <Row>
            <Col span={24} style={{ padding: "20px 10px 20px 10px" }}>
              <Title level={5}>
                <ArrowLeftOutlined onClick={() => props.card(false)} />
              </Title>
            </Col>
          </Row> */}
          <Row>
            <Col span={24} style={{ textAlign: "left" }}>
              {props.info.hex.length ? (
                <>
                  <br></br>

                  {props.info.hex.map((h, i) => (
                    <>
                      <Row style={{ padding: "0px 30px 0px 30px" }}>
                        {/* <Col span="6">{i + 1}</Col> */}
                        <Col span="5">
                          <text>{h.operator}</text>
                          {/* <Statistic title={h.samples} value={h.operator} /> */}
                        </Col>
                        <Col span="3">
                          <text>{h.samples}</text>
                        </Col>
                        <Col span="16">
                          {" "}
                          <div
                            style={{
                              // marginTop: "10px",
                              width: `${(h.avg.toFixed(2) * 100) / 20}%`,
                              height: "20px",
                              borderRadius: "0px",
                              backgroundColor: colorScheme[i],
                              color: "white",
                              textAlign: "left",
                              paddingLeft: "20px",
                              fontSize: "12px",
                              minWidth: "30%",
                              maxWidth: "100%",
                            }}
                          >
                            <b>{h.avg.toFixed(2)} Mbp/s</b>
                          </div>
                          {/* <Text strong>{h.avg ? h.avg.toFixed(2) : 0}</Text> */}
                        </Col>
                        {/* <Col span="6">
                          <span>{h.samples} tests</span>
                        </Col> */}
                        {/* <Col span="6">{h.samples}</Col> */}
                      </Row>
                      <Row>
                        <Col span={24} style={{ textAlign: "center" }}>
                          <br></br>
                        </Col>
                      </Row>
                    </>
                  ))}
                  {/* <Row>
                    <Col span="6">
                      {" "}
                      <Text level={5} strong>
                        Position
                      </Text>
                    </Col>
                    <Col span="6">
                      <Text level={5} strong>
                        Operator
                      </Text>
                    </Col>
                    <Col span="6">
                      <Text level={5} strong>
                        Average
                      </Text>
                    </Col>
                    <Col span="6">
                      <Text level={5} strong>
                        Active Tests
                      </Text>
                    </Col>
                  </Row>
                  <Row>
                    <Divider />
                  </Row>
                  {props.info.hex.map((h, i) => (
                    <>
                      <Row>
                        <Col span="6">{i + 1}</Col>
                        <Col span="6">{h.operator}</Col>
                        <Col span="6">
                          {" "}
                          <Text strong>{h.avg ? h.avg.toFixed(2) : 0}</Text>
                        </Col>
                        <Col span="6">{h.samples}</Col>
                      </Row>
                      <Row>
                        <Col span="24">
                          {" "}
                          <br></br>
                        </Col>
                      </Row>
                    </>
                  ))} */}
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          {/* <Row>
            <Col span={24}>
              <br></br>
              <Button
                style={{ bottom: "10px", right: "10px", float: "right" }}
                type="primary"
                shape="round"
                onClick={() => {
                  ShowPoints();
                }}
              >
                {HexMap ? "Hide Samples" : "Show Samples"}
              </Button>
            </Col>
          </Row> */}
        </div>
        <div style={{ flex: 2 }}>
          {" "}
          {Loading ? (
            <>
              <div>
                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <Button type="default" shape="round" loading>
                      Loading Bin Map
                    </Button>
                  </Col>
                </Row>
              </div>{" "}
            </>
          ) : (
            <></>
          )}
          {!Loading ? (
            <Row>
              {/* <Col span={24} style={{ textAlign: "center" }}>
                <Divider />
              </Col> */}
              <Col span={24} style={{ textAlign: "left", paddingLeft: "30px" }}>
                <Statistic title=" Active Tests" value={PointsData.length} />
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>
                <br></br>
              </Col>
              <Col span={24}>
                <ComaprisonMap hexagons={PointsData} hex={props.info.id} />
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* <Card bordered={true} style={{ width: 500 }}> */}

      {/* </Card> */}
    </>
  );
}
export default BinCard;
