import React from "react";
import StatusBar from "./StatusBar";
import ResultBar from "./ResultTable";
import Measurement from "./Measurment";

import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineRise,
} from "react-icons/ai";
import {
  Statistic,
  Progress,
  Row,
  Col,
  Divider,
  Button,
  Descriptions,
} from "antd";

import "./../App.css";

function SideBar(props) {
  return (
    <>
      <div
        className="side-bar"
        style={{
          textAlign: "center",
          height: "auto",
          width: "500px",
          position: "fixed",
          left: "0px",
          right: "0px",
          margin: "auto",
          top: "10%",
          zIndex: 11,
        }}
      >
        <Row justify="center">
          <Col span={24}>
            {" "}
            <br></br>
            <h2>Sampling Statistics</h2>
          </Col>

          <Col span={24}>
            <br></br>
          </Col>
          <Col span={24} style={{ backgroundColor: "#f7f7f7" }}>
            {" "}
            <Row>
              <Col span={24}>
                <br></br>{" "}
              </Col>
              <Col span={24}>
                <Statistic
                  title="RF Samples"
                  value="130351078"
                  valueStyle={{ fontSize: 30 }}
                />
              </Col>
              <Col span={24}>
                <br></br>{" "}
              </Col>
              <Col span={24}>
                <Statistic
                  title="Active Tests"
                  value={props.tests}
                  valueStyle={{ fontSize: 30 }}
                />
              </Col>
            </Row>
            <br></br>
          </Col>

          <Col span={24}>
            <br></br>
          </Col>
          <Col
            span={24}
            style={{ textAlign: "left", padding: "0px 40px 0px 40px" }}
          >
            <Row>
              <Col span={12}> DL File Size :</Col>
              <Col span={12}>
                {" "}
                <span>10 Mb</span>
              </Col>
            </Row>
            <Row>
              <Col span={12}> Technology :</Col>
              <Col span={12}>
                {" "}
                <span>LTE</span>
              </Col>
            </Row>
            <Row>
              <Col span={12}>Handsets :</Col>
              <Col span={12}>
                {" "}
                <span>3</span>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            {" "}
            <Divider />
          </Col>
          <Col
            span={24}
            style={{
              textAlign: "left",
              padding: "0px 40px 0px 40px",
            }}
          >
            <Row>
              <Col span={12}> Buildup area covered :</Col>
              <Col span={12}>
                {" "}
                <span>96.3%</span>
              </Col>
            </Row>
            <Row>
              <Col span={12}> Population Covered :</Col>
              <Col span={12}>
                {" "}
                <span>99%</span>
              </Col>
            </Row>
            <Col span={24}>
              {" "}
              <Divider />
            </Col>
            <Row>
              <Col span={12}> Data Collection :</Col>
              <Col span={12}>
                {" "}
                <span>960 Hours</span>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            {" "}
            <Divider />
          </Col>

          <Col
            span={24}
            justify="right"
            style={{ textAlign: "right", padding: "0px 40px 0px 40px" }}
          >
            {" "}
            <Button type="dashed" onClick={() => props.updatebar(!props.bar)}>
              Close
            </Button>
          </Col>
          <Col span={24}>
            <br></br>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default SideBar;
