import React, { useState, useEffect } from "react";
import DeckGL from "@deck.gl/react";
import ReactMapGL, { FlyToInterpolator } from "react-map-gl";
import { H3HexagonLayer } from "@deck.gl/geo-layers";
import { AmbientLight, PointLight, LightingEffect } from "@deck.gl/core";
import BinCard from "./ComparisonCard";
import { Select, Drawer } from "antd";
import { h3ToGeo, h3ToGeoBoundary } from "h3-js";
import { Button, Radio, Switch, Row, Col, Statistic, Card } from "antd";
import axios from "axios";
import * as d3 from "d3";
import {
  BulbOutlined,
  SignalFilled,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import { specialChars } from "@testing-library/user-event";
const { Option } = Select;
const HexArea = 0.7373276;
export default function Map(props) {
  const ambientLight = new AmbientLight({
    color: [153, 226, 180],
    intensity: 2,
  });

  const pointLight1 = new PointLight({
    color: [250, 163, 7],
    intensity: 0.44,
    position: [-0.144528, 0.739968, 100],
  });

  const pointLight2 = new PointLight({
    color: [202, 240, 248],
    intensity: 0.6,
    position: [-3.807751, 54.104682, 100],
  });

  const lightingEffect = new LightingEffect({
    ambientLight,
    pointLight1,
    pointLight2,
  });
  const material = {
    ambient: 0.7,
    diffuse: 0,
    shininess: 10,
    specularColor: [245, 176, 65],
  };
  const [DGLayer, UpdateDGLayer] = useState([]);
  const [Ambient, UpdateAmbient] = useState(lightingEffect);
  const [LatLng, UpdateLatLng] = useState([-118.243683, 34.052235]);
  const [Hexagons, UpdateHexagons] = useState([]);
  const [Map, UpdateMap] = useState(false);
  const [Elevation, UpdateElevation] = useState(false);
  const [SCard, UpdateCard] = useState(false);
  const [Comparison, UpdateComparison] = useState(false);
  const [HexInfo, UpdateHexInfo] = useState({
    id: null,
    average: null,
    count: null,
    percentage: null,
    oldVal: null,
  });
  const [FilteredHex, UpdateFilteredHex] = useState({
    count: 0,
    average: 0,
    score: 0,
  });
  const [initialViewState, setInitialViewState] = useState({
    longitude: LatLng[0],
    latitude: LatLng[1],
    zoom: 4,
    maxZoom: 20,
    pitch: 0,
    bearing: 0,
  });
  function RangeBox(color) {
    return {
      height: "15px",
      width: "100px",
      backgroundColor: color,
    };
  }
  function selectHexagons(hexid) {
    console.log(hexid);
    let boundryCoords = h3ToGeoBoundary(hexid._id);
    console.log("Boundry is", boundryCoords);
    UpdateHexInfo({
      id: hexid._id,
      // count: hexid.count,
      // average: hexid.average ? hexid.average : 0,
      // percetnage: hexid.percentage,
      // oldVal: hexid.oldVal,
      hex: hexid.info,
    });
    UpdateCard(true);
  }

  function getOperatorColor(operator) {
    switch (operator) {
      case "ATT":
        return [222, 49, 99];
      case "VZW":
        return [255, 127, 80];
      case "TMO":
        return [86, 171, 145];
      default:
        return [186, 104, 200];
    }
  }

  function createhexLayer(data) {
    if (data) {
      if (data.length) {
        let showLibraries = true;
        const hexLayer = new H3HexagonLayer({
          id: props.compare ? "comaparison-layer" : "hexagon-layer",
          data,
          pickable: true,
          wireframe: false,
          filled: true,
          coverage: 0.998, //spaces between hexagons
          elevationScale: Elevation,
          opacity: 0.7,
          getHexagon: (d) => d._id,

          getFillColor: (d) =>
            d.color ? d.color : getOperatorColor(d.operator),
          material,

          onClick: (d) => {
            selectHexagons(d.object);
          },
          updateTriggers: {
            getFillColor: (d) => d.color,
            getColor: (d) => d.color,
          },
          transitions: {
            getFillColor: 100,
          },
        });

        let hexCenter = h3ToGeo(data[data.length - 1]._id);
        setInitialViewState({
          longitude: hexCenter[1],
          latitude: hexCenter[0],
          zoom: 10,
          maxZoom: 17,
          pitch: 60,
          bearing: 10,
          pitch: Elevation ? 70 : 0,
          bearing: Elevation ? 0 : 0,
        });
        // UpdateLatLng(hexCenter);
        UpdateDGLayer([hexLayer]);
      }
    }
  }

  useEffect(() => {
    createhexLayer(props.hexagons);
  }, [props.hexagons, Elevation]);

  useEffect(() => {
    if (props.reset) {
      setInitialViewState({
        longitude: LatLng[0],
        latitude: LatLng[1],
        zoom: 1,
        maxZoom: 20,
        pitch: 0,
        bearing: 0,
      });
      createhexLayer([]);
      UpdateCard(false);
    }
  }, [props.reset]);

  // useEffect(() => {
  //   let hexLayer = createhexLayer(props.selectHexagons);
  //   UpdateDGLayer([hexLayer]);
  // }, [pr const { size } = this.state;ops.selectedhexagons]);
  const { size } = "large";

  return (
    <>
      <div>
        {" "}
        {props.hexagons.length ? (
          <>
            <div
              style={{
                position: "fixed",
                zIndex: 10,
                left: "570px",
                // marginRight: "30%",
                top: "20px",
                color: "white",
                display: "none",
              }}
            >
              {props.coverage > 0 ? <></> : <></>}
            </div>{" "}
            <div
              style={{
                position: "fixed",
                zIndex: 2,
                bottom: "20px",
                left: "18%",
                // width: "300px",
              }}
            >
              {" "}
              <Row>
                <Col span={8} order={1}>
                  <div style={RangeBox("rgba(222, 49, 99)")}></div>
                </Col>
                <Col span={8} order={2}>
                  <div style={RangeBox("rgba(255, 127, 80)")}></div>
                </Col>
                <Col span={8} order={3}>
                  <div style={RangeBox("rgba(86, 171, 145)")}></div>
                </Col>
              </Row>
              {props.avg.length ? (
                <>
                  <Row
                    style={{
                      color: "#f7f7f7",
                      fontSize: ".8em",
                      textAlign: "center",
                    }}
                  >
                    {props.avg.map((d, i) => (
                      <>
                        <Col span={8} order={1}>
                          {d._id}
                        </Col>
                      </>
                    ))}

                    {/* <Col span={8} order={1}>
                      DNA
                    </Col>
                    <Col span={8} order={2}>
                      Elisa
                    </Col>
                    <Col span={8} order={3}>
                      TeliaSonera
                    </Col> */}
                  </Row>{" "}
                </>
              ) : (
                <> </>
              )}
            </div>{" "}
          </>
        ) : (
          <> </>
        )}
        <div className="groupcard">
          <Drawer
            title="Bin Details"
            placement="right"
            onClose={() => {
              UpdateCard(!SCard);
            }}
            visible={SCard}
            width={500}
            bodyStyle={{ overflow: "hidden", padding: "0px" }}
            style={{ width: "500px", overflow: "hidden" }}
          >
            <BinCard info={HexInfo} card={UpdateCard} data={props.hexagons} />
          </Drawer>
          {/* {SCard ? (
            <BinCard info={HexInfo} card={UpdateCard} data={props.hexagons} />
          ) : (
            <></>
          )} */}
        </div>
        <DeckGL
          style={{ position: "fixed", zIndex: 1, left: "00px", top: "0px" }}
          initialViewState={initialViewState}
          controller={true}
          effects={[Ambient]}
          layers={DGLayer}
          // getTooltip={({ object }) =>
          //   object && ` ${object.color} and ${object.average} `
          // }
        >
          <ReactMapGL
            reuseMaps
            // mapStyle={mapStyle}
            longitude={LatLng[0]}
            latitude={LatLng[1]}
            preventStyleDiffing={true}
            transitionDuration={1000}
            transitionInterpolator={new FlyToInterpolator()}
            mapStyle="mapbox://styles/mapbox/dark-v10"
            mapStyle={
              Map
                ? "mapbox://styles/mapbox/dark-v10"
                : "mapbox://styles/mapbox/satellite-streets-v11"
            }
            // "mapbox://styles/mapbox/outdoors-v11"
            mapStyle="mapbox://styles/mapbox/dark-v10"
            //`mapbox://styles/mapbox/satellite-v9`
            // mapStyle="mapbox://styles/mapbox/outdoors-v11"
            // mapStyle="mapbox://styles/mapbox/light-v10"
            mapboxApiAccessToken="pk.eyJ1Ijoic2FsbWFuanZkIiwiYSI6ImNrc3JjaXFlZzBsanUybnRmZHFhNGpmdmYifQ.cYc--QH_zO99dPIsBMSAcQ"
          />
        </DeckGL>
      </div>
    </>
  );
}
