import React from "react";
import "./../App.css";
import { Select } from "antd";
const { Option } = Select;
function OperatorList(props) {
  return (
    <>
      <Select
        showSearch
        style={{ width: "100%" }}
        placeholder="Select an operator"
        optionFilterProp="children"
        onChange={(e) => {
          props.selectoperator(e);
        }}
        // onFocus={onFocus}
        // onBlur={onBlur}
        // onSearch={onSearch}
        // filterOption={(input, option) =>
        //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        // }
      >
        {props.operators
          ? props.operators.map((data, index) => {
              return (
                <Option key={index} value={data}>
                  {data}
                </Option>
              );
            })
          : ""}
      </Select>
    </>
  );
}
export default OperatorList;
